
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Paginator from "./Paginator";


const MainBanner = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [numberNews,setNumberNews] = useState(0);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('https://dffm3d05ol.execute-api.us-east-1.amazonaws.com/prod/getNews') // Reemplaza con tu URL de API Gateway
        .then(response => {
            setNewsItems(response.data);
            setNumberNews(response.data.length);
            setLoading(false);    
            console.log("completado get");
            //console.log(response.data);
            //console.log(response.data.length);
        })
        .catch(error => {
            console.error('Error fetching the news:', error);
            
        });
        
    },[]);

    return(
        <div class="main-banner">
            
            <div class="container">
            <div class="row">
                <div class="col-lg-6 align-self-center">
                <div class="caption header-text">
                    <h6>Soy kachimbo la web que te ayuda a ingresar a la universidad</h6>
                    <h2>APRENDE MATEMÁTICA, FÍSICA Y QUÍMICA PAGANDO S/1 SOL POR CADA CLASE</h2>
                    <h3>TU MEJOR ALIADO PARA LA UNIVERSIDAD</h3>
                    
                    <p>Asesoria psicológica, grupos de estudio, libros en pdf, ejercicios resueltos, noticias y examenes de admisión de todas las universidades de Perú.</p>
                    {/* <div class="search-input">
                    <form id="search" action="#">
                        <input type="text" placeholder="Buscar material de estudio" id='searchText' name="searchKeyword" onKeyPress="handle" />
                        <button >Buscar</button>
                    </form>
                    </div> */}
                </div>
                </div>
                <div class="col-lg-3 offset-lg-2 d-none d-lg-block">
                <div class="right-image">
                    <img src="https://img.freepik.com/foto-gratis/sonriente-joven-mujer-segura-ordenador-portatil-mirando-camara-aislada-sobre-fondo-blanco_231208-9497.jpg?w=360&t=st=1698966375~exp=1698966975~hmac=0a33ca937f702cd57a6ee794be628d9b1060633874bcd90ec102cd3f12d88512" class="img-fluid w-100 w-md-75 w-sm-50 rounded shadow" alt=""/>
                    <span class="price">$22</span>
                    <span class="offer"></span>
                </div>
                </div>
                
            </div>
            <div class="row">
                <div id="news" class="caption header-text">
                    <h2>Novedades</h2>
                    {!isLoading && newsItems && <Paginator data={newsItems} sizeData={numberNews}/>}
                </div>
            </div>
            </div>
        </div>
    );
};

export default MainBanner;

