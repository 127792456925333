import React from 'react';
import Header from './HeaderComponent';
import MainBanner from './MainBannerComponent';

import Publicity from './PublicityComponent';
import Footer from './FooterComponent';
//import Loader from './LoaderComponent';
// import { Counter } from '../features/counter/Counter';

const Main = () => {
  return (
    <div>
        {/* <Loader/>  falta implementar su js */}
        <Header/>
        {/* <Counter/> */}
        <Publicity/>
        <MainBanner/>
        
        
        <Footer/>
    </div>
  )
};

export default Main;
